'use client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faPinterest, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

import { ILink } from '@lamesarv-sdk/types';
import { ILayoutFooter, ILayoutSocialIcon } from '@/types/layout';

interface IFooterSectionProps {
  title: string;
  links: ILink[];
}

const FooterSection = (props: IFooterSectionProps) => {
  return (
    <div className="text-center md:text-left">
      <h3 className="text-sm font-semibold leading-6 text-white">{props.title}</h3>
      <ul role="list" className="mt-4 space-y-4">
        {props.links.map((link) => (
          <li key={link.url} className="">
            <a href={link.url} className="text-sm text-gray-300 hover:text-white">
              {link.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const FooterSocialLink = (props: ILayoutSocialIcon) => {
  const socialIcons = {
    facebook: faFacebook,
    instagram: faInstagram,
    pinterest: faPinterest,
    twitter: faXTwitter,
    youtube: faYoutube,
  };

  return (
    <a href={props.url} className="text-gray-500 hover:text-gray-400">
      <span className="sr-only">{props.title}</span>
      <FontAwesomeIcon icon={socialIcons[props.type]} className="w-6 h-6 fill-blue-500" />
    </a>
  );
};

export const LayoutFooter = (props: ILayoutFooter) => {
  return (
    <div className="bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 text-center md:text-left">
            <img className="mx-auto md:mx-0 h-10" src={props.logoUrl} alt="RV Show USA" />
            <p className="text-sm leading-6 text-gray-300">RV show events & expos near you.</p>
            <div className="flex justify-center md:justify-start space-x-6">
              {props.socialIcons.map((icon, index) => (
                <FooterSocialLink key={index} {...icon} />
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-6 xl:col-span-2 xl:mt-0">
            <div className="w-full flex flex-col gap-8 md:grid md:grid-cols-3">
              {props.menus.map((menu, index) => {
                return (
                  <FooterSection
                    key={index}
                    title={menu.label}
                    links={menu.items.map((item) => ({
                      title: item.title,
                      url: item.url,
                    }))}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <div className="flex flex-col md:flex-row gap-1 justify-center text-sm leading-5 text-gray-400">
            <div className="text-center">
              <a className="text-gray-400 hover:text-white" href="https://www.rvshowusa.com/" target="_self">
                RVShowUSA.com
              </a>
            </div>
            <div className="hidden md:block">|</div>
            <div className="flex flex-row gap-1 justify-center">
              <span>Call for Show Info:</span>
              <a
                className="text-gray-400 hover:text-white"
                href={`tel:1${props.phone.replace(/\D/g, '')}`}
                target="_self"
              >
                {props.phone}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
