'use client';

import { PropsWithChildren } from 'react';

import { LayoutProvider } from '@/contexts/LayoutContext';

import { LayoutFooter } from './LayoutFooter';
import { LayoutHeader } from './LayoutHeader';
import { LayoutPreviewFlag } from './LayoutPreviewFlag';

import { ILayout } from '@/types/layout';

interface ILayoutProps extends ILayout {
  appVersion?: string;
}

export const Layout = (props: PropsWithChildren<ILayoutProps>) => {
  return (
    <LayoutProvider logoUrl={props.header.logoUrl}>
      {props.isPreview && <LayoutPreviewFlag />}
      <header className="font-header">
        <LayoutHeader {...props.header} />
      </header>
      <main className="font-body">{props.children}</main>
      <footer className="font-footer relative">
        <LayoutFooter {...props.footer} />
        {props.appVersion && (
          <div className="absolute left-1/2 -translate-x-1/2 bottom-2 text-xs text-neutral-600 leading-none">
            Version: {props.appVersion}
          </div>
        )}
      </footer>
    </LayoutProvider>
  );
};
